import React from "react";

export default function Paragraph({ data, className }) {

    let divStyle = ""
    divStyle += className + ' '


    const items = data.map((item) => {



        let style = '';

        item.multiline ? style += 'my-2 ' : style += 'inline '

        if (item.bold) { style += 'font-bold ' };
        if (item.italic) { style += 'italic ' };
        if (item.color) {
            switch (item.color) {
                case "red":
                    style += 'text-red-600'
                    break
                default:
                    break
            }
        }
        if (item.align) {
            switch (item.align) {
                case "justify":
                    divStyle += 'text-justify '
                    break
                case "center":
                    divStyle += 'text-center '
                    break
                default:
                    break
            }
        }

        if (item.href) {
            let href = item.href
            return <a className="text-blue-600 hover:text-blue-800 underline cursor-pointer break-all" href={href} >{item.text}</a>
        }

        return <p className={style.trim()}>{item.text}</p>
    })

    return (
        <div className={divStyle.trim()}>{items}</div>
    )
}