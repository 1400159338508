import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import slugify from "slugify";

export default function SecondaryNavLinks() {

    const { t } = useTranslation('navigation');
    const navMenuLinks = t(`secondary`, { returnObjects: true, lng: 'en' })

    return (
        <div className="ml-auto h-full">
            {t('secondary', { returnObjects: true }).map((link, index) => (
                <Link to={'/pages/' + slugify(navMenuLinks[index], { lower: true })} className="mx-2 px-1 items-center hover:text-black hover:bg-white h-full inline-flex cursor-pointer">{link}</Link>
            ))}
        </div>
    )
}