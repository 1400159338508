import React from "react";
import { useTranslation } from "react-i18next";

export default function TitlePage() {

    const { t } = useTranslation('main-page')

    return (
        <div className="ml-28 t-10 w-[100%-7rem] justify-center flex flex-col relative h-full">
            <img src={"../assets/images/title-images/main-page.jpg"} alt="" className="w-full h-full" />
            <div className="bg-[rgba(0,0,0,0.5)] w-full self-center absolute  text-center p-4 text-white">
                <h1 className="font-bold text-4xl">SMART INSURANCE BROKER</h1>
                <p className="font-semibold text-2xl">{t('slogan')}</p>
            </div>
            
        </div>
    )
}