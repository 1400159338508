import React from "react";
import HomeIcon from "../atoms/HomeIcon";
import LanguageSelect from "../molecules/LanguageSelect";
import SecondaryNavLinks from "../molecules/SecondaryNavLinks";


export default function SecondaryNav() {

    return (
        <div className="w-full bg-black text-white z-50">
            <div className="flex items-center h-10">
                <HomeIcon />
                <SecondaryNavLinks />
                <LanguageSelect />
            </div>
        </div >
    )
}