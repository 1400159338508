import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PrimaryNav3rdLv from "../molecules/PrimaryNav3rdLv";
import PrimaryNav2ndLv from "../molecules/PrimaryNav2ndLv";

export default function PrimaryNav() {
    const { t } = useTranslation('navigation')

    const images = require.context("../../assets/images/nav/primary", true)
    const imageList = images.keys().map(image => images(image))

    const [secondLevelActive, setSecondLevelActive] = useState(0)

    const keys = Object.keys(t('primary', { returnObjects: true }))

    const closeNav = () => {
        setSecondLevelActive(0)
    }


    return (
        <div className={"bg-red-900 text-white text-xs w-28 h-[calc(100vh-40px)] fixed left-0 flex flex-col justify-center z-50"}>

            {keys.map((key, index) => {
                const navMenuItem = t(`primary.${key}`, { returnObjects: true })
                const navMenuLinks = t(`primary.${key}`, { returnObjects: true, lng: 'en' })

                return (
                    <>
                        <div className="select-none cursor-pointer group w-full text-center py-1 hover:text-black hover:bg-white" onClick={() => setSecondLevelActive(index + 1)}>
                            <img className="pointer-events-none group-hover:invert-0 invert w-full" src={imageList[index]} alt={navMenuItem.alt} />
                            <span>{navMenuItem.text}</span>
                        </div>
                        {
                            secondLevelActive === (index + 1) &&
                            <div>
                                <ul className="overflow-y-auto absolute pb-5 left-28 top-0 w-[25vw] h-full bg-black bg-opacity-90 text-base flex flex-col gap-0">
                                    {navMenuItem.list.map((item, index) => (
                                        <>
                                            {typeof item == 'object' ? <PrimaryNav3rdLv id={index} list={item} links={navMenuLinks.list[index]} onClick={closeNav} /> : <PrimaryNav2ndLv id={index} item={item} link={navMenuLinks.list[index]} onClick={closeNav} />}
                                        </>

                                    ))}
                                </ul>
                            </div>
                        }

                        {
                            secondLevelActive === (index + 1) &&
                            <div className="absolute top-0 left-28 ml-[25vw] w-screen h-screen bg-opacity-15 bg-black" onClick={() => setSecondLevelActive(0)}></div>
                        }
                    </>
                )
            })}
        </div>
    )
}