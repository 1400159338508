import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import i18next from "i18next";
import pageDataParser from "../../helpers/pageDataParser";
import TitleBlock from "../organisms/TitleBlock";

export default function Page() {

    const params = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation(params.title);

    const nsExists = i18next.hasResourceBundle(i18next.language, params.title)

    useEffect(() => {
        if (!nsExists) {
            navigate('/404')
        }
    })

    return (
        <div id="content" className="ml-28 w-[100%-7rem] justify-center flex flex-col">

            <div id='title-block'>
                {TitleBlock(t('title', { returnObjects: true }), params.title)}
            </div>


            <div className="text-justify max-w-4xl mx-10 self-center">
                <div id='body'>
                    {t('body', { returnObjects: true }).map((section) => (
                        <section className="my-6">
                            {section.map((part) => {
                                return pageDataParser(part, params.title)
                            })}
                        </section>
                    ))}
                </div>
            </div>
        </div>
    )
}