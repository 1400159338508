import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";



export default function LanguageSelect() {

    const { i18n } = useTranslation('navigation');
    const lngs = ['hu', 'en', 'de']
    const [lngSelectActive, setLngSelectActive] = useState(false);
    if (!lngs.includes(i18next.language)) {

        console.log(i18next.language)

        switch (i18next.language.split('-')[0]) {
            case 'hu':
                i18next.changeLanguage('hu')
                break
            case 'en':
                i18next.changeLanguage('en')
                break
            case 'de':
                i18next.changeLanguage('de')
                break
            default:
                i18next.changeLanguage(i18next.options.fallbackLng)
        }
    }

    return (
        <div className="relative">
            <button className="hover:text-black hover:bg-white px-2 h-10 w-16 place-items-center flex" onClick={() => setLngSelectActive(!lngSelectActive)}>
                <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg" aria-labelledby="languageIconTitle" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"> <title id="languageIconTitle">Language</title> <circle cx="12" cy="12" r="10" /> <path stroke-linecap="round" d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z" /> <path stroke-linecap="round" d="M2.5 9L21.5 9M2.5 15L21.5 15" /> </svg>
                <span className="text-inherit pl-1">{i18n.language}</span>
            </button>
            {
                lngSelectActive &&
                <div className="absolute w-full bg-gray-700 text-white text-center z-[100]">
                    <ul className="">
                        {lngs.filter((lng) => i18n.language !== lng).map((lng) => (
                            <li className="cursor-pointer py-2" key={lng} onClick={() => { i18n.changeLanguage(lng); setLngSelectActive(false) }}>{lng}</li>
                        ))}
                    </ul>
                </div>
            }
        </div>
    )
}