import React from "react";
import PrimaryNav from "./PrimaryNav";
import SecondaryNav from "./SecondaryNav";

function Nav() {

    return (
        <nav className="sticky top-0 z-40">
            <SecondaryNav />
            <PrimaryNav />
        </nav>
    )

}

export default Nav;