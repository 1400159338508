import React from "react"
import { Link } from "react-router-dom"

export default function NotFound() {




    return (
        <div className="">
            <div>404 Page Not found</div>
            <Link to='/' className="px-2 mt-2 mx-2 rounded-md border-slate-700 border-2 bg-slate-600 text-white">Back to Home</Link>
        </div>
    )
}