import React from "react";
import pageDataParser from "../../helpers/pageDataParser";

export default function List({ data, marker, className }) {

    // console.log(ordered)
    let listStyle = ''
    if (marker) {
        switch (marker) {
            case "disc":
                listStyle += 'list-disc '
                break
            case "decimal":
                listStyle += "list-decimal "
                break
            case "revert":
                listStyle += "list-[revert] "
                break
            case "none":
                listStyle += "list-none "
                break
            default:
                break
        }
    }

    listStyle += className;

    
    const items = data.map((item, index) => {
        let listItemStyle = ""
        if (item.type !== "text") { listItemStyle += "list-none " }
        if (item.data[0].bold) {listItemStyle += 'marker:font-bold '}
        if (item.data[0].italic) {listItemStyle += 'marker:italic '}

        return <li key={index} className={listItemStyle.trim()}>{pageDataParser(item)}</li>

        // if (isList) {
        //     return <li className='list-none' key={index}><List data={item.data} marker={item.marker} className="ml-[1.6rem] " /></li>
        // }
        // else {
        //     return <li key={index}><Paragraph data={item.data} /></li>
        // }
    })



    return (
        <ul className={listStyle.trim()}>{items}</ul>
    )
}