import React, { useState } from "react";
import { Link } from "react-router-dom";
import slugify from "slugify";


export default function PrimaryNav3rdLv(params) {

    const { links, list, id } = params

    const arrow = <svg className="h-2 w-auto self-center pr-2" id="eNkapZzKb9g1" viewBox="-1 -1 8 5">
        <g>
            <line x1="3" y1="3" x2="0" y2="0" stroke="currentColor" stroke-width="2" stroke-linecap="round" id="line1" />
            <line x1="3" y1="3" x2="6" y2="0" stroke="currentColor" stroke-width="2" stroke-linecap="round" id="line2" />
        </g>
    </svg>

    const [select2ndActive, setSelect2ndActive] = useState(false)

    return (
        <>
            <li className="cursor-pointer px-4 py-1 mx-1 my-1 text-white bg-black border-2 border-red-600 box-border flex">
                {arrow}
                <p onClick={() => setSelect2ndActive(!select2ndActive)} id={`${id}`} className="block w-full h-full">{list[0]}</p>

            </li>
            {
                select2ndActive &&
                <ul className="">
                    {list.slice(1).map((item, index) => {
                        // { console.log(`item: ${item}\nindex: ${index}`) }
                        // { console.log(`item(en): ${links}`) }
                        return (< li className="cursor-pointer" >

                            <Link to={`pages/${slugify(links[index + 1], { lower: true, remove: /[,'']/g })}`} onClick={params.onClick} className="underline px-3 mx-1 cursor-pointer block text-white">
                                {item}
                            </Link>

                        </li>)
                    })}
                </ul >
            }

        </>
    )
}