import React from "react"
import List from "../components/atoms/List"
import Paragraph from "../components/atoms/Paragraph"
import Image from "../components/atoms/Image"


export default function pageDataParser(pageItem, pageName) {


    switch (pageItem.type) {
        case "text":
            return <Paragraph data={pageItem.data} className={"my-2 "} />

        case "list":
            return <List data={pageItem.data} marker={pageItem.marker} className={`ml-[1.6rem] my-2 [&_div]:my-0 `} />

        case "line":
            return <hr className={`border-black my-4 `} />

        case "image":
            const imageData = pageItem.data
            imageData.sourceStub = `../../assets/images/${pageName}/`
            return <Image data={imageData} className={''} />

        default:
            break
    }
}