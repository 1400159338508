import React from "react";

export default function TitleBlock(data, pageName) {

    // title, subtitle, quote (text, source)
    
    const title = <h1 className="font-bold text-3xl max-w-4/5">{data.title.toUpperCase()}</h1>
    let subtitle;
    let subsubtitle;
    let quote;

    if (data.subtitle) {subtitle = <h2 className="text-xl">{data.subtitle}</h2>}
    if (data.subsubtitle) {subsubtitle = <h3>{data.subsubtitle}</h3>}
    if (data.quote) {
        quote = (
            <div className="flex flex-col">
                <q className="italic">{data.quote.text}</q>
                {data.quote.source ? <p className="">-{data.quote.source}</p> : null}
            </div>
        )
    }


    return (
        <div className={`h-[80vh] w-full flex flex-col relative text-white`}>
            <img src={`../assets/images/title-images/${pageName}.jpg`} alt="" className="w-full h-full object-cover" />
            <div className="bg-[rgba(0,0,0,0.5)] w-full self-center absolute bottom-0 text-center p-4 max-w-4/5">
                {title}
                {subtitle}
                {subsubtitle}
                {quote}
            </div>
        </div>
    )
}