import React from "react";
import { Link } from "react-router-dom";
import slugify from "slugify";


export default function PrimaryNav2ndLv(params) {

    const { link, item, id } = params

    return (
        <li className="cursor-pointer px-4 py-1 mx-1 my-1 bg-black border-2 border-red-600">
            <Link to={`pages/${slugify(link, { lower: true, remove: /[,'']/g })}`} id={id} className="block w-full h-full" onClick={params.onClick}>
                {item}
            </Link>
        </li>
    )
}