import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n.js'
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import NotFound from './components/pages/NotFound';
import Nav from './components/organisms/Nav';
import Page from './components/pages/Page';
import TitlePage from './components/pages/TitlePage';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <NavWrapper />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/',
        element: <TitlePage />
      },
      {
        path: 'pages/:title',
        element: <Page />
      }
    ]
  },
  {
    path: '/404',
    element: <NotFound />
  }
])

function NavWrapper() {
  return (
    <div>
      <Nav />
      <Outlet />
    </div>
  )
}

root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <RouterProvider router={router} />
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
