import React from "react";

export default function Image({data, className}) {

    let divStyle = ''
    let textPosition = ''

    switch (data.position) {
        case "left":
            divStyle += 'items-start '
            break
        case "right":
            divStyle += 'items-end '
            textPosition += 'text-right '
            break
        default:
            divStyle += 'items-center '
            textPosition += 'text-center '
            break
    }

    const text = (textData) => {
        
        let textStyle = ''

        switch (textData.position) {
            case "left":
                textStyle += 'text-left '
                break
            case 'right':
                textStyle += 'text-right '
                break
            case 'center':
                textStyle += 'text-center '
                break
            default:
                textStyle += textPosition
                break
        }


        if (textData.bold) { textStyle += 'font-bold ' };
        if (textData.italic) { textStyle += 'italic ' };

        return <p className={textStyle.trim()}>{textData.text}</p>
        
    }

    let title
    let caption

    if (data.title) {title = text(data.title)}
    if (data.caption) {caption = text(data.caption)}


    return (
        <div className={`flex flex-col ${divStyle}`.trim()}>
            {title}
            <img src={data.sourceStub + data.source} alt={data.alt} className={"w-fit max-w-[80%] " + className.trim()} />
            {caption}
        </div>
    )

   

}